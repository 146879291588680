
const digest = 'bd8afa33669b330aca6d14cdb9999d9c6f49f19be4ad821c70ee14aafd924dad';
const css = `._negative_1u5vo_1 {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_1u5vo_1"};
export { css, digest };
  