
const digest = '0af3c821b0addf8e5138d322acd69ac42b9e486a65631ee352af5adeb9626073';
const css = `/* 940px */

._stickyHeader_rv29y_3 {
  position: sticky;
  top: 0;
  margin: 0;
  z-index: 99;
}

._filtersView_rv29y_10,
._filters_rv29y_10 {
  margin: 0;
  padding: 0.5rem 0 0;
  background: var(--color-white);
}
._filtersView_rv29y_10 {
  display: none;
}
@media only screen and (min-width: 960px) and (max-width: 1279px) {
  ._filters_rv29y_10 {
    padding: 0.5rem 1rem 0;
  }
}
@media only screen and (min-width: 1472px) {
  ._filtersView_rv29y_10,
  ._filters_rv29y_10 {
    display: block;
    padding: 1rem 1rem 0;
  }
}

._page_rv29y_32 {
  padding: 0.5rem 0 6rem;
}
@media only screen and (min-width: 1472px) {
  ._page_rv29y_32 {
    padding: 1.5rem 1rem 6rem;
  }
}

._blocks_rv29y_41 {
  padding-bottom: 40rem;
}
@media only screen and (min-width: 960px) {
  .minibar ._blocks_rv29y_41 {
    margin: 0 0.5rem;
  }
}
@media only screen and (min-width: 960px) {
  ._blocks_rv29y_41 {
    margin: 0 0.5rem;
  }
}

._blockAddGroup_rv29y_55 {
  height: 4em;
  padding: 0.75em;
  width: 100%;
  border-top: 1px solid var(--color--neutral-20);
  flex-wrap: wrap;
}

._blockAddGroupSelect_rv29y_63 {
  width: 290px;
}

._deleteButton_rv29y_67 {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
._deleteButtonSvg_rv29y_72 {
  fill: var(--color--neutral-10);
}
._deleteButton_rv29y_67:hover ._deleteButtonSvg_rv29y_72 {
  fill: var(--color-neutral-40);
}
._deleteButton_rv29y_67:focus-within ._deleteButtonSvg_rv29y_72 {
  fill: var(--color-neutral-40);
}

._deleteButtonSvgDisabled_rv29y_82 {
  fill: var(--color--neutral-10);
}

._entries_rv29y_86 {
  display: flex;
  padding: 0 0.75em 1em;
  order: 3;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  ._entries_rv29y_86 {
    order: 2;
    padding: 0;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._entries_rv29y_86 {
    order: 2;
    border-left: 2px solid var(--color--neutral-20);
    border-right: 2px solid var(--color--neutral-20);
    padding: 0;
    width: auto;
  }
}
@media only screen and (min-width: 1472px) {
  ._entries_rv29y_86 {
    order: 2;
    border-left: 2px solid var(--color--neutral-20);
    border-right: 2px solid var(--color--neutral-20);
    padding: 0;
    width: auto;
  }
}
._entriesIsRejected_rv29y_117 {
  background: repeating-linear-gradient(
    45deg,
    #fad0d5,
    #fad0d5 8px,
    #fce7ea 8px,
    #fce7ea 16px
  );
}

div._headerSearch_rv29y_127,
div._headerSelect_rv29y_128 {
  min-width: 100%;
  margin-right: 0;
}
div._headerSearch_rv29y_127 {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 640px) {
  div._headerSearch_rv29y_127 {
    width: 23em;
    margin: 0 0.25rem;
    min-width: auto;
  }
  div._headerSelect_rv29y_128 {
    width: 16em;
    margin: 0 0.25rem;
    min-width: auto;
  }
}

._headerWeekPicker_rv29y_148 {
  display: none;
}
@media only screen and (min-width: 1279px) {
  ._headerWeekPicker_rv29y_148 {
    display: block;
    margin-left: auto;
  }
}

._rowTotals_rv29y_158 {
  display: none;
  order: 5;
}

@media only screen and (min-width: 1472px) {
  ._rowTotals_rv29y_158 {
    display: flex;
  }
}
@media only screen and (min-width: 1279px) {
  .minibar ._rowTotals_rv29y_158 {
    display: flex;
  }
}

._rowTotalsHours_rv29y_174 {
  align-items: center;
  border-right: 1px solid var(--color--neutral-20);
  display: flex;
  padding: 0.75em;
  width: 3.5em;
}
@media only screen and (min-width: 1600px) {
  ._rowTotalsHours_rv29y_174 {
    width: 4.5em;
  }
}

._rowTotalsExpenses_rv29y_187 {
  align-items: center;
  border-right: none;
  display: flex;
  padding: 0.75em;
  width: 79px;
}

._rowTotalsLabel_rv29y_195 {
  color: var(--color--neutral-10);
  font-size: 0.75rem;
  line-height: 1;
}

._rowTotalsValue_rv29y_201 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  margin-left: auto;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"stickyHeader":"_stickyHeader_rv29y_3","filtersView":"_filtersView_rv29y_10","filters":"_filters_rv29y_10","page":"_page_rv29y_32","blocks":"_blocks_rv29y_41","blockAddGroup":"_blockAddGroup_rv29y_55","blockAddGroupSelect":"_blockAddGroupSelect_rv29y_63","deleteButton":"_deleteButton_rv29y_67","deleteButtonSvg":"_deleteButtonSvg_rv29y_72","deleteButtonSvgDisabled":"_deleteButtonSvgDisabled_rv29y_82","entries":"_entries_rv29y_86","entriesIsRejected":"_entriesIsRejected_rv29y_117","headerSearch":"_headerSearch_rv29y_127","headerSelect":"_headerSelect_rv29y_128","headerWeekPicker":"_headerWeekPicker_rv29y_148","rowTotals":"_rowTotals_rv29y_158","rowTotalsHours":"_rowTotalsHours_rv29y_174","rowTotalsExpenses":"_rowTotalsExpenses_rv29y_187","rowTotalsLabel":"_rowTotalsLabel_rv29y_195","rowTotalsValue":"_rowTotalsValue_rv29y_201"};
export { css, digest };
  